import React from 'react';
import References from './References';
import ThemeSwitcher from './ThemeSwitcher';

const Header: React.FC = () => {
  const CSSBlock = 'header';
  return (
    <div className={CSSBlock}>
      <ThemeSwitcher />
      <References />
    </div>
  );
};

export default Header;
