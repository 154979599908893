export interface CoreData {
  name: string;
  longName: string;
  description: string;
  left: SectionData;
  middle: SectionData;
  right: SectionData;
  references: string[];
}

export interface SectionData {
  label: string;
  categories: SectionCategory[];
  cards: Record<string, SectionCard[]>;
}

export interface SectionCategory {
  label: string;
  categoryID: string;
}

export interface SectionCard {
  heading?: string;
  keyInformation?: SectionCardKeyInformation[];
  content?: (string | string[] | ContentTypeKeyValuePair | ContentType3D)[];
}

export const isContentTypeText = (val: any): val is string =>
  !!val && typeof val === 'string';

export const isContentTypeList = (val: any): val is string[] =>
  Array.isArray(val) && (val.length === 0 || typeof val[0] === 'string');

export interface ContentType3D {
  asset: string;
  scale?: number;
}
export const isContentType3D = (val: any): val is string[] =>
  !!val.asset && typeof val.asset === 'string';

export interface ContentTypeKeyValuePair {
  key: string;
  value: string;
}
export const isContentTypeKeyValuePair = (
  val: any
): val is ContentTypeKeyValuePair =>
  !!val.key &&
  !!val.value &&
  typeof val.key === 'string' &&
  typeof val.value === 'string';

export interface SectionCardKeyInformation {
  key: string;
  value: string;
}

export enum LayoutSections {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
}
