import React from 'react';
import Header from '../components/Header';
import Content from '../sections/Content';
import USBData from './data/usb';
import AppTheme from './AppTheme';

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

const Root: React.FC = () => {
  document.body.classList.toggle('isMobile', !!isMobileDevice());
  return (
    <>
      <AppTheme />
      <Header />
      <div className={`title`}>
        {USBData.name}
        <span className={`title__long-title`}>[{USBData.longName}]</span>
      </div>
      <div className={`description`}>{USBData.description}</div>
      <Content />
    </>
  );
};

export default Root;
