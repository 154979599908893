import * as THREE from 'three';

import Camera from './Core/camera';
import Renderer from './Core/renderer';
import Resources from './Core/resources';
import Lighting from './Core/lighting';

let ThreeJSViews = {};

export default class Simulator {
  _sizes = {
    width: 240,
    height: 160,
  };
  _scene = null;
  _canvas = null;

  constructor(canvas, resource, scale = 1.0) {
    ThreeJSViews[resource] = this;

    this._canvas = canvas;
    this._scene = new THREE.Scene();
    this._lighting = new Lighting(this);
    this._camera = new Camera(this);
    this._renderer = new Renderer(this);
    this._resources = new Resources({
      simulation: this,
      resource,
      scale,
      onLoad: model => {
        this.scene.add(model);
      },
    });

    this._tick();
  }

  get canvas() {
    return this._canvas;
  }
  get camera() {
    return this._camera._camera;
  }
  get scene() {
    return this._scene;
  }
  get sizes() {
    return this._sizes;
  }
  get resources() {
    return this._resources._collection;
  }

  _tick() {
    this._camera.onTick();
    this._renderer.onTick();
    this._lighting.onTick();
    window.requestAnimationFrame(this._tick.bind(this));
  }
}
