import React, { useEffect, useState } from 'react';
import { LocalStorage, LSKeys, ThemeMode } from '../core/localStorage';

const setAppTheme = (isDarkMode: boolean) =>
  document.body.classList.toggle(`theme-dark`, isDarkMode);

const ThemeSwitcher: React.FC = () => {
  const CSSBlock = 'theme-switcher';
  const [themeState, setThemeState] = useState(ThemeMode.SYSTEM);

  const [modifierClass, setModifierClass] = useState('system');

  useEffect(() => {
    const theme = LocalStorage.get(LSKeys.THEME);
    setThemeState(theme);

    theme === ThemeMode.SYSTEM
      ? setAppTheme(window.matchMedia('(prefers-color-scheme: dark)').matches)
      : setAppTheme(theme === ThemeMode.DARK);
  }, []);

  useEffect(() => {
    setModifierClass(
      themeState === ThemeMode.LIGHT
        ? 'light'
        : themeState === ThemeMode.DARK
        ? 'dark'
        : 'system'
    );

    let isDarkMode = themeState === ThemeMode.DARK;
    const changeHandler = (e: any) => setAppTheme(!!e.matches);
    const mq = window.matchMedia('(prefers-color-scheme: dark)');

    if (themeState === ThemeMode.SYSTEM) {
      mq.addEventListener('change', changeHandler);
      isDarkMode = mq.matches;
    }
    setAppTheme(
      themeState === ThemeMode.SYSTEM
        ? mq.matches
        : themeState === ThemeMode.DARK
    );

    return () => mq.removeEventListener('change', changeHandler);
  }, [themeState]);

  const onClick = (type: ThemeMode) => {
    setThemeState(type);
    LocalStorage.set(LSKeys.THEME, type);
  };

  return (
    <div className={`${CSSBlock} ${CSSBlock}--${modifierClass}`}>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'system' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeMode.SYSTEM)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-desktop"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>System</div>
      </div>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'light' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeMode.LIGHT)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-sun"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>Light</div>
      </div>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'dark' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeMode.DARK)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-moon"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>Dark</div>
      </div>
      <div className={`${CSSBlock}__indicator`}></div>
    </div>
  );
};

export default ThemeSwitcher;
