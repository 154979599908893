import React, { useEffect, useState } from 'react';

import { CSSVARS, setCSSVar } from '@helpers/theme.helper';

const fps = 60;
const duration = 3;
const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2;

interface AppThemeProps {}

let themeInterval = 0;

const AppTheme: React.FC<AppThemeProps> = ({}) => {
  const [themeHue, setThemeHue] = useState(Math.round(Math.random() * 360));

  let animatedHue = Math.round(Math.random() * 360);
  const hueRotationDuration = 20; // seconds

  const animate = () => {
    requestAnimationFrame(() => {
      animatedHue += 360 / fps / hueRotationDuration;
      setCSSVar(CSSVARS.THEME_BG_HUE, animatedHue.toString());
      onSetThemeHue(animatedHue);
      animatePos();
      if (ISPRODUCTION) {
      }
    });
  };

  const onSetThemeHue = (hue: number) => {
    setCSSVar(CSSVARS.THEME_BG_HUE, hue);
    document.body.classList.toggle(
      `palette-light`,
      themeHue >= 60 && themeHue <= 180
    );
    setCSSVar(CSSVARS.THEME_BG_01, `hsla(${hue + 90}, 55%, 55%, 1)`);
    setCSSVar(CSSVARS.THEME_BG_02, `hsla(${hue - 90}, 55%, 55%, 1)`);
  };

  let pos = 0;
  const animatePos = () => {
    pos += 1 / fps / duration;
    setCSSVar(CSSVARS.THEME_BG_POS, (0 + easeInOutSine(pos) * 100).toString());
  };

  useEffect(() => {
    themeInterval = window.setInterval(animate, 1000 / fps);
    return () => clearInterval(themeInterval);
  }, []);

  return <></>;
};

export default AppTheme;
