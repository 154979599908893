import React from 'react';
import { useState } from 'react';
import { SectionCategory } from '../core/contentHelper';

interface VersionSelectorProps {
  items: SectionCategory[];
  onChange: (item: SectionCategory) => void;
}

const VersionSelector: React.FC<VersionSelectorProps> = ({
  items,
  onChange,
}) => {
  const CSSBlock = 'version';
  const [activeVersion, setActiveVersion] = useState(items[0]);

  const onItemClick = item => {
    setActiveVersion(item);
    onChange(item);
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__selector`}>
        {items.map((item, i) => (
          <div
            className={`${CSSBlock}__option ${
              item.label === activeVersion.label
                ? `${CSSBlock}__option--active`
                : ''
            }`}
            key={i}
            onClick={() => onItemClick(item)}>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VersionSelector;
