import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

export default class Resources {
  _model = null;

  constructor({ resource, scale, onLoad, simulation }) {
    this._simulation = simulation;

    const loadingManager = new THREE.LoadingManager(e => {
      onLoad(this._model);
    });

    const dracoLoader = new DRACOLoader(loadingManager);
    dracoLoader.setDecoderPath('/draco/');
    const gltfLoader = new GLTFLoader(loadingManager);

    gltfLoader.load(`${window.location.origin}/assets/${resource}`, gltf => {
      const obj = new THREE.Group();
      const offset = new THREE.Vector3(0, 0, 0);
      obj.scale.set(0.25 * scale, 0.25 * scale, 0.25 * scale);
      while (gltf.scene.children.length) {
        gltf.scene.children[0].position.add(offset);
        gltf.scene.children[0].castShadow = true;
        gltf.scene.children[0].receiveShadow = false;
        obj.add(gltf.scene.children[0]);
      }
      // console.log(obj);
      obj.children[0].receiveShadow = false;
      obj.children[0].castShadow = false;
      this._model = obj;
    });
  }
}
