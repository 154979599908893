import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { SectionCategory } from '../core/contentHelper';

interface DropdownProps {
  items: SectionCategory[];
  onChange: (item: SectionCategory) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ items, onChange }) => {
  const CSSBlock = 'dropdown';
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const dropdownTriggerRef = useRef<HTMLButtonElement>(null);

  const onItemClick = index => {
    setOpen(false);
    setActive(index);
    onChange(items[index]);
  };

  useEffect(() => {
    const closeOpenMenus = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        open &&
        !dropdownRef.current.contains(e.target as Node) &&
        !dropdownTriggerRef!.current!.contains(e.target as Node)
      )
        setOpen(false);
    };
    document.addEventListener('mousedown', closeOpenMenus);
    return () => document.removeEventListener('mousedown', closeOpenMenus);
  }, [open]);

  return (
    <div className={`${CSSBlock} `}>
      <button
        className={`${CSSBlock}__trigger`}
        onClick={() => setOpen(!open)}
        ref={dropdownTriggerRef}>
        {items[active].label}
        <i className="fa-solid fa-caret-down"></i>
      </button>
      <ul
        className={`${CSSBlock}__container ${
          open ? `${CSSBlock}__container--open` : ''
        }`}
        ref={dropdownRef}>
        {items.map((item, i) => (
          <li
            key={i}
            className={`${CSSBlock}__list-item`}
            onClick={() => onItemClick(i)}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
