import { CoreData } from '../contentHelper';

export default {
  name: 'USB',
  longName: 'Universal Serial Bus',
  description:
    'In a world of evolving proprietary technologies, USB was designed. With a core purpose to standardise the connection of peripherals to personal computers, the standard has evolved to a series which specifies connection protocols, power standards and so much more.',

  left: {
    label: 'USB Connectors',
    categories: [
      { label: 'Standard USB Connectors', categoryID: 'usbStandard' },
      { label: 'USB Mini Connectors', categoryID: 'usbMini' },
      { label: 'USB Micro Connectors', categoryID: 'usbMicro' },
      { label: 'USB Type C', categoryID: 'usbTypeC' },
    ],
    cards: {
      usbStandard: [
        {
          heading: 'USB Type A',
          content: [
            { asset: 'USB-A.gltf' },
            'In a world filled with connectors in ever-growing shapes and sizes, USB attempted to create a common (universal) connector. 30 years later, this is still a hugely popular connector which is still found on many PCs, game consoles, TVs, peripherals and home media devices.',
            { asset: 'USB-A-SS.gltf' },
            'A second version was developed for the USB 3.0 SuperSpeed specification, adding additional pins whilst preserving full backwards compatibility with USB 2.0 and USB 1.1.',
          ],
        },
        {
          heading: 'USB Type B',
          content: [
            { asset: 'USB-B.gltf' },
            'Often found on larger devices, such as printers and external hard drives, the Type-B connector was primarily released to prevent the risk of connecting two host computers.',
            { asset: 'USB-B-SS.gltf' },
            'Similarly to the Type-A connector, there are two variants - one for up to USB 2.0 data transfers and one released later to coincide with the USB 3.0 specification.',
          ],
        },
        {
          heading: 'Pinouts',
          content: [
            'The initial versions of the Type-A and Type-B ports (valid up to USB 2.0 data transfer speeds) have four pins:',
            [
              'One for power (VBUS)',
              'One for ground (GND)',
              'Two for differential data signals (D-, D+)',
            ],
            'With the introduction of USB 3.0 SuperSpeed, these now have a total of 9 pins (5 recessed pins):',
            [
              'One for power (VBUS)',
              'Two for ground (GND and GND_DRAIN)',
              'Two for differential data signals (D-, D+) ',
              '2 differential pairs for SuperSpeed data - transmission (TX) and receivers (RX)',
            ],
          ],
        },
      ],
      usbMini: [
        {
          content: [
            { key: 'Released', value: 'April 2000' },
            'Both Mini connectors were introduced alongside the USB 2.0 specification, and were primarily targeted toward smaller devices such as MP3 players, sat navs, digital cameras, PDAs and tablet computers. They were popularised for their advantage of being able to transfer data and charge the device, a revolutionary concept for the time.',
            'In addition to each having its dedicated receptacle, a USB Mini-AB receptacle was introduced (by the original USB OTG standard) which accepted both Mini-A and Mini-B plugs.',
            'A fifth pin was introduced into the connector which serves as the ID pin, used to enable the USB OTG functionality by detecting the type of plug inserted (A or B). The device with a Mini-A plug becomes the power-supplying device and the device with a Mini-B plug becomes the consumer device.',
          ],
        },
        {
          heading: 'USB Type-A Mini',
          content: [
            { asset: 'USB-Mini-A.gltf', scale: 1.5 },
            'The Type-A Mini connector and the Type-AB receptacle were discontinued in May 2007, shortly after the introduction of the Micro connectors, but the Type-B Mini connector is still supported.',
          ],
        },
        {
          heading: 'USB Type-B Mini',
          content: [
            { asset: 'USB-Mini-B.gltf', scale: 1.5 },
            'Unlike the Type-A Mini connector, the uptake of this connector increased and it has not yet been officially discontinued. However, it does not support USB OTG functionality and is limited to USB 2.0 standards.',
          ],
        },
      ],
      usbMicro: [
        {
          heading: '',
          content: [
            { key: 'Released', value: 'Janurary 2007' },
            'Designed to replace the previous USB Mini connectors, the USB Micro connectors are a similar width to the Mini connectors but approximately half the thickness; this was to allow for integration into even thinner devices, such as smartphones, PDAs and Bluetooth accessories.',
            'The Micro plug design is rated for over 10,000 connect-disconnect cycles, designed to reduce mechanical wear on the device, but this did not help the reliability of both the plug and receptacle. The Micro connector is widely known for its infamous lack of reliability.',
            'Similar to the Mini-AB receptacle, a Micro-AB receptacle was introduced to facilitate USB OTG functionality. However, there was never a Micro-A receptacle.',
          ],
        },
        {
          heading: 'USB Type-A Micro',
          content: [
            { asset: 'USB-Micro-A.gltf', scale: 1.5 },
            'The lesser known Type-A Micro connector was released alongside the Type-B Micro connector for the USB 2.0 standard but was never updated with USB 3.0 SuperSpeed functionality.',
          ],
        },
        {
          heading: 'USB Type-B Micro',
          content: [
            { asset: 'USB-Micro-B.gltf', scale: 1.5 },
            'The Type-B Micro, commonly just referred to as "Micro USB", connector became the standard for a large chunk of the portable electronics market, including smartphones and portable hard drives, dominating the Android device space until the late 2010s.',
            { asset: 'USB-Micro-B-SS.gltf', scale: 1.5 },
            'Exclusively to the Type-B Micro, a USB 3.0 variant was released. This had an extension to house the extra five pins required by the USB 3.0 specification. This allowed the Type-B Micro receptacle to provide backwards compatibility with USB 2.0 Micro-B plugs.',
          ],
        },
      ],
      usbTypeC: [
        {
          content: [
            { key: 'Released', value: 'August 2014' },
            'This is the most recent specification for a USB connector, to deliver a "future-proof" connector. USB Type-C (commonly known as USB-C) is designed for smaller, thinner and lighter devices with a rotationally symmetrical connector allowing the plug to be reversible.',
            { asset: 'USB-C.gltf', scale: 1.5 },
            'Unlike previous connectors, this is not limited to any USB standard. It could be used for USB data transfer, USB Power Delivery, Thunderbolt 3, DisplayPort Alt Mode or other technologies. It is intended to be a universal connector, independent from the USB spec itself, which can be used by multiple standards.',
            'This is largely made possible by the Alternate Mode, which allows the data pins in the connector to carry other signals; e.g. DisplayPort Alternate Mode (DP Alt Mode) allows a direct DisplayPort signal to be carried using a USB-C cable from a compatible laptop to a TV or monitor.',
          ],
        },
        {
          heading: 'Pinouts',
          content: [
            'The USB-C pin layout contains a total of 24 pins, 12 on each side of the connector in a symmetrical layout (providing the reversible featureset):',
            [
              '4 power (VBUS) pins',
              '4 ground (GND) pins',
              '2 differential pairs (DP / DN) pins for USB 2.0 data',
              '4 shielded differential pairs for SuperSpeed data - 2 pairs for transmission (TX) and 2 pairs of receivers (RX)',
              '2 Sideband Use (SBU) pins',
              '2 Configuration Channel (CC) pins',
            ],
            'The orientation of the cable is determined by the CC pins. The male connector replaces one CC pin with a VCONN pin, powering electronics and leaving the other CC pin to indicate the orientation, and only has one differential pair for USB 2.0 data.',
          ],
        },
      ],
    },
  },

  middle: {
    label: 'USB Versions',
    categories: [
      {
        label: 'USB 1.*',
        categoryID: 'usb1.*',
      },
      {
        label: 'USB 2.*',
        categoryID: 'usb2.*',
      },
      {
        label: 'USB 3.*',
        categoryID: 'usb3.*',
      },
      {
        label: 'USB 4.*',
        categoryID: 'usb4.*',
      },
    ],
    cards: {
      'usb1.*': [
        {
          heading: 'USB 1.0',
          content: [
            { key: 'Release date', value: 'January 1996' },
            { key: 'Total Bandwidth', value: '1.5 Mbit/s - 12 Mbit/s' },
            { key: 'Connectors', value: 'Type A, Type B' },
            'Although this was the first official version of the USB standard, very few devices made it to market with the 1.0 version',
          ],
        },
        {
          heading: 'USB 1.1',
          content: [
            { key: 'Release date', value: 'August 1996' },
            { key: 'Total Bandwidth', value: '1.5 Mbit/s - 12 Mbit/s' },
            { key: 'Connectors', value: 'Type A, Type B' },
            'USB 1.1 was the first version that saw mass adoption in the market.',
          ],
        },
      ],
      'usb2.*': [
        {
          heading: 'USB 2.0',
          content: [
            { key: 'Release date', value: 'April 2000' },
            {
              key: 'Total Bandwidth',
              value: '480 Mbit/s (High Speed or Hi-Speed)',
            },
            {
              key: 'Connectors',
              value:
                'Type A, Type A Mini/Micro, Type B, Type B Mini/Micro, Type C',
            },
            'USB 2.0 saw the introduction of many consumer creature comforts including USB On-The-Go, allowing storage devices to be connected to portable electronics, USB Battery Charging, supporting host charger behaviours, and Link Power Management, adding a sleep power state.',
            'This was also the first introduction of a miniature connector for the USB standard.',
            'The USB 2.0 generation never had any 2.* revisions to its specification, only superseded in 2008 by USB 3.0.',
          ],
        },
      ],
      'usb3.*': [
        {
          heading: 'Who fu-USB-ed up the naming scheme?',
          content: [
            'USB 3.0 generation may have brought many great things, but it began the terrible naming scheme that led to this wonderful website; let me explain.',
            `The original name when released was "USB 3.0" - makes sense, right? This later got renamed when the next version in the 3.* generation launched to "USB 3.1 Gen 1" with the new version labelled "USB 3.1 Gen 2". Why wasn't this just "USB 3.0" and "USB 3.1"? There is no viable reason, but wait... it gets worse.`,
            `When the final revision of the 3.0 generation was launched, they all got renamed again. The original "USB 3.0" became "USB 3.2 Gen 1" and the original "USB 3.1 Gen 2" became "USB 3.2 Gen 2". You may think the third revision was launched as "USB 3.2 Gen 3", right? Nope. This has been labelled "USB 3.2 Gen 2x2" which only makes sense if you understand how the electrical hardware for this standard works (the "x2" describes 2 lanes for double the speed).`,
            `Who's to blame? The USB-IF. Let's not sugarcoat that.`,
          ],
        },
        {
          heading: 'USB 3.2 Gen 1',
          content: [
            { key: 'Previous name(s)', value: 'USB 3.1 Gen 1, USB 3.0' },
            { key: 'Release date', value: 'November 2008' },
            { key: 'Total Bandwidth', value: '5.0 Gbit/s (SuperSpeed)' },
            { key: 'Lanes', value: '1' },
            { key: 'Maximum cable length', value: '2m' },
            {
              key: 'Connectors',
              value: 'Type A, Type B, Type B Micro, Type C',
            },
            'USB 3.2 Gen 1 introduces a huge leap forward for data transfer speeds, with its SuperSpeed transfer mode. It also allowed for devices to take advantage of higher currents of up to 900 mA.',
          ],
        },
        {
          heading: 'USB 3.2 Gen 2',
          content: [
            { key: 'Previous name(s)', value: 'USB 3.1 Gen 2, USB 3.1' },
            { key: 'Release date', value: 'July 2013' },
            { key: 'Total Bandwidth', value: '10.0 Gbit/s (SuperSpeed+)' },
            { key: 'Lanes', value: '1' },
            { key: 'Maximum cable length', value: '1m' },
            {
              key: 'Connectors',
              value: 'Type C',
            },
            'The second iteration in the USB 3.* specification introduces the higher bandwidth SuperSpeed+ transfer mode, doubling the total bandwidth but reducing the rated cable length.',
          ],
        },
        {
          heading: 'USB 3.2 Gen 2x2',
          content: [
            { key: 'Previous name(s)', value: 'USB 3.2' },
            { key: 'Release date', value: 'September 2017' },
            { key: 'Total Bandwidth', value: '20.0 Gbit/s (SuperSpeed+)' },
            { key: 'Lanes', value: '2' },
            { key: 'Maximum cable length', value: '1m' },
            {
              key: 'Connectors',
              value: 'Type C',
            },
            'Released 9 years after the introduction of USB 3.0, this preserves the SuperSpeed and SuperSpeed+ transfer modes but increases the theoretical bandwidth to double the previous version by adding a second lane for data transfer; at a signalling rate of 10 Gbit/s per lane, this provides a maximum of 20 Gbit/s over a single USB-C cable.',
          ],
        },
      ],
      'usb4.*': [
        {
          heading: "Let's rename again!",
          content: [
            'USB4, yes the lack of whitespace and minor version numbering here is correct, introduced a new naming convention. From the offset, the USB-IF declared three upcoming transfer modes which will be indicated in the naming scheme: 20 Gbit/s, 40 Gbit/s and 80 Gbit/s.',
            "Well, that didn't last long. The latest announcement from the USB-IF is for USB4 Version 2.0 (the 80 Gbit/s variant), retroactively renaming the first iteration as USB4 Version 1.0.",
          ],
        },
        {
          heading: 'USB4 Version 1.0',
          content: [
            { key: 'Other name(s)', value: 'USB4 20 Gbit/s, USB4 40 Gbit/s' },
            { key: 'Release date', value: 'August 2019' },
            {
              key: 'Total Bandwidth',
              value: '20.0 Gbit/s (SuperSpeed+), 40.0 Gbit/s (SuperSpeed+)',
            },
            { key: 'Lanes', value: '2' },
            { key: 'Power delivery', value: '7.5W - 100W PD' },
            { key: 'Maximum cable length', value: '0.8m' },
            { key: 'Connectors', value: 'Type C' },
            "A major technological leap, based on Intel's Thunderbolt protocol, allowed for the aggregate bandwidth to double, enabling multiple data and display protocols. The specification outlines full backwards compatibility with USB 3.2, USB 2.0 and Thunderbolt 3 technologies, also including DisplayPort Alternate Mode and other Alternate Modes.",
          ],
        },
        {
          heading: 'USB4 Version 2.0',
          content: [
            { key: 'Other name(s)', value: 'USB4 80 Gbit/s' },
            { key: 'Release date', value: 'September 2022' },
            { key: 'Total Bandwidth', value: '80.0 Gbit/s (SuperSpeed+)' },
            { key: 'Lanes', value: '4' },
            { key: 'Power delivery', value: '240W PD' },
            { key: 'Maximum cable length', value: '0.8m' },
            { key: 'Connectors', value: 'Type C' },
            'Maintaining the same backwards compatibility as USB4 Version 1.0, to achieve the new maximum theoretical bandwidth of 2x the previous version you must use a newly-defined USB-C 80 Gbit/s active cable with a total of 4 lanes - making it one of the highest performing standards on the consumer market.',
            'This also updates the supported technologies to include support for the latest versions of the DisplayPort and PCIe specifications.',
            'Supposedly, this was created specifically to benefit users on a variety of devices for single-cable solutions for docking, high resolution and performance monitors and multiple peripherals. ',
          ],
        },
      ],
    },
  },

  right: {
    label: 'USB Features',
    categories: [
      { label: 'USB Battery Charging', categoryID: 'usbBC' },
      { label: 'USB Power Delivery', categoryID: 'usbPD' },
      {
        label: 'Mobile Fast Charging Technologies',
        categoryID: 'usbFastCharge',
      },
      { label: 'USB On-the-Go', categoryID: 'usbOTG' },
      { label: 'DisplayPort Alternate Mode', categoryID: 'usbDP' },
    ],
    cards: {
      usbBC: [
        {
          heading: 'Battery Charging (USB BC) Specification',
          content: [
            'The USB BC specification defines the charging port specification, with the latest revision 1.2 released in 2010.',
            'The USB specification defines three types of USB ports:',
            [
              'Dedicated Charging Port (DCP)',
              'Standard Downstream Port (SDP)',
              'Charging Downstream Port (CDP)',
            ],
            'In USB 1.0 and 2.0 devices, the standard downstream port is capable of delivering current up to 0.5A. With USB 3.0, the standard downstream current was upgraded to 0.9A. The dedicated charging port, as named, is purely for power, allowing currents up to and beyond 1.5A. The charging downstream port combines the benefits of DCP and SCP, allowing for higher-current charging in addition to compliance with the USB data specification.',
            'BC 1.2 also allows the device being charged to provide feedback to the host about its charging requirements, optimizing battery charging and safety.',
          ],
        },
        {
          heading: 'What does this actually mean?',
          content: [
            'As per the specification, USB 1.0 and 2.0 generation devices can provide, in low-power devices, 100 mA at 5 V, outputting 0.5W. For high-power devices, they can provide 500 mA at 5V, outputting a total of 2.5W.',
            'For USB 3.0 (SuperSpeed) devices (also referred to as USB 3.2 Gen 1 and USB 3.2 Gen 2 devices), low-power devices can get 150 mA at 5V, outputting 0.75 W, and high-power devices can get up to 900 mA at 5V, outputting 4.5W.',
            'Multi-lane USB 3.0 devices (also known as USB 3.2 Gen 2x2 devices) can get 1.5 A at 5 V, outputting a total of 7.5W.',
          ],
        },
      ],
      usbPD: [
        {
          heading: 'Power Delivery (USB-PD) Specification',
          content: [
            { key: 'Released', value: 'July 2012 (USB PD rev. 1)' },
            "USB PD was formalized, for the standard USB Type A and USB Type B ports, to deliver increased power (above the theoretical maximum of USB BC's 7.5W) for devices with higher power demands. USB-PD compatible devices can request higher voltages and currents from their hosts, up to a total power draw of 100 Watts.",
            'The following configurations are supported:',
            [
              '2 Amps at 5 Volts, for a consumption of 10 Watts',
              '1.5 Amps at 12 Volts, for a consumption of 18 Watts',
              '3 Amps at 12 Volts, for a consumption of 36 Watts',
              '3 Amps at 20 Volts, for a consumption of 60 Watts',
              '5 Amps at 20 Volts, for a consumption of 100 Watts',
            ],
          ],
        },
        {
          heading: 'USB-PD rev. 2.0',
          content: [
            'USB PD revision 2.0 provides far greater flexibility in the power profiles, defining four normative voltage levels at 5 V, 9 V, 15 V and 20 V.',
            'The following configurations are supported:',
            [
              '0.1 A - 3.0 A at 5 Volts, for a consumption range of up to 0.5 - 15 Watts',
              '1.67 - 3.0 A at 9 Volts, for a consumption range of 15 - 27 Watts',
              '1.8 A - 3.0 A at 15 Volts, for a consumption range of 27 - 45 Watts',
              '2.25 A - 5.0 A at 20 Volts, for a consumption range of 45 - 100 Watts',
            ],
          ],
        },
        {
          heading: 'USB-PD rev. 3.0',
          content: [
            'USB PD revision 3.0 defines a new protocol, Programmable Power Supply (PPS), and adds Fast Role Swap (FSR) to the specification.',
            'PPS allows the device to request stepwise changes in real-time in both the current and voltage, ensuring the charge provided is more efficient by decreasing the conversion loss during charging.',
            'FSR allows the host device to quickly change its power role to instead become a consumer, to prioritise maintaining a constant data connection. A key consideration for this was to prevent data loss that may occur when power is unexpectedly removed from the device.',
          ],
        },
        {
          heading: 'USB-PD rev 3.1',
          content: [
            { key: 'Released', value: 'May 2021' },
            'The 3.1 revision of the specification introduces a couple of notable protocols: Extended Power Range (EPR) and Adjustable Voltage Supply (AVS).',
            'EPR introduces much higher voltages into the specification (28 V, 36 V and 48 V), outputting a maximum of 240 Watts, and retroactively renamed all previous power modes to Standard Power Range (SPR). ',
            'AVS allows more granular specifications of voltages from a range of 15 V to 48 V in 100 mV increments',
            'The latest 3.1 specification allows for the following additional configurations:',
            [
              '3.57 A - 5.0 A at 28 Volts, for a consumption range of 100 - 140 Watts',
              '3.89 A - 5.0 A at 36 Volts, for a consumption range of 140 - 180 Watts',
              '3.75 A - 5.0 A at 48 Volts, for a consumption range of 180 - 240 Watts',
            ],
          ],
        },
      ],
      usbFastCharge: [
        {
          heading: 'Qualcomm Quick Charge',
          content: [
            'Quick Charge (QC) is a proprietary charging protocol developed by Qualcomm, largely used within portable devices running on Qualcomm chips such as smartphones. Both the host (charger) and the device must support QC, and each generation is backwards compatible.',
            'This is made possible by increasing the output voltage supplied by the charger, whilst adding extra safety techniques to prevent battery damage. The initial specification allowed for a total power output of 10 Watts, more than double the USB BC specification of the time, but the latest QC 5.0 allows for greater than 100 Watts.',
          ],
        },
        {
          heading: 'VOOC Charging',
          content: [
            {
              key: 'Other names',
              value: 'Warp charge (OnePlus), Dart Charge (Realme)',
            },
            'Developed by BBK Electronics, VOOC is a manufacturer-specific standard used by the branch of electronics manufacturers under the BBK Electronics umbrella.',
            'Theoretically, VOOC can push as much power as it can, not limiting the charging power of the device in use like competing standards. This has been achieved by moving a large chunk of the heat-generating component from the phone to the charger itself. In contrast to USB-PD and Qualcomm QC, VOOC increases the voltage to provide faster-charging speeds. This means that not only does VOOC require a compatible device and charging adapter, but the cable itself must be VOOC compatible.',
            'Later, the company developed a unique charging system to deliver power to two battery cells connected in a series, dubbed SuperVOOC. Essentially, this allows a doubling of the charging power. The latest iteration, known as SuperVOOC, has demonstrated a charging power output of 240 Watts, whilst the highest supported device on the market is currently at 150 Watts.',
          ],
        },
      ],
      usbOTG: [
        {
          content: [
            { key: 'Introduced', value: '2001' },
            'USB OTG introduces the concept of a device performing both host and peripheral roles using a Host Negotiation Protocol.',
            'For example, your smartphone is usually the peripheral device when connected to a computer, but this allows your smartphone to act as a host if you connect a USB flash drive to it directly.',
          ],
        },
      ],
      usbDP: [
        {
          content: [
            'Exclusive to the USB-C connector, DisplayPort Alt Mode (DP Alt Mode) allows the USB-C connection to transmit a DisplayPort signal instead of a USB signal. This allows a direct video output with a DisplayPort signal using a compatible cable, without any additional drivers required.',
          ],
        },
      ],
    },
  },

  references: [
    'https://en.wikipedia.org/wiki/USB',
    'https://en.wikipedia.org/wiki/USB_hardware',
    'https://en.wikipedia.org/wiki/USB-C',
    'https://en.wikipedia.org/wiki/USB_On-The-Go',
    'https://en.wikipedia.org/wiki/Quick_Charge',
    'https://www.cablematters.com/Blog/USB-C/what-is-usb4',
    'https://www.lifewire.com/usb-type-b-connector-2626033',
    'https://www.androidauthority.com/supervooc-fast-charging-686000/',
    'https://www.tripplite.com/products/usb-connectivity-types-standards',
    'https://www.techadvisor.com/article/742967/usb-speeds-types-and-features-explained.html',
    'https://petapixel.com/2020/04/30/one-port-to-rule-them-all-usb-4-will-support-80gbps-displayport-2-0-standard/',
    'https://www.usb.org/sites/default/files/2022-09/USB%20PG%20USB4%20Version%202.0%2080Gbps%20Announcement_FINAL.pdf',
  ],
} as CoreData;
