import React, { useRef, useState } from 'react';
import {
  LayoutSections,
  SectionCategory,
  SectionData,
} from '../core/contentHelper';
import Card from './Card';
import Dropdown from './Dropdown';
import VersionSelector from './VersionSelector';

interface LayoutSectionProps {
  data: SectionData;
  alignment: LayoutSections;
  activeSection: LayoutSections;
  updateSectionNext: () => void;
  updateSectionPrevious: () => void;
}

const CSSBlock = 'layout-section';

const getLayoutActiveClass = (
  activeSection: LayoutSections,
  alignment: LayoutSections
) => {
  return alignment === LayoutSections.LEFT
    ? getLayoutClassLeft(activeSection)
    : alignment === LayoutSections.MIDDLE
    ? getLayoutClassMIddle(activeSection)
    : getLayoutClassRight(activeSection);
};

const getLayoutClassLeft = (activeSection: LayoutSections) => {
  return activeSection === LayoutSections.LEFT
    ? `${CSSBlock}--active`
    : activeSection === LayoutSections.MIDDLE
    ? `${CSSBlock}--inactive-left`
    : `${CSSBlock}--inactive-right`;
};
const getLayoutClassMIddle = (activeSection: LayoutSections) => {
  return activeSection === LayoutSections.MIDDLE
    ? `${CSSBlock}--active`
    : activeSection === LayoutSections.RIGHT
    ? `${CSSBlock}--inactive-left`
    : `${CSSBlock}--inactive-right`;
};
const getLayoutClassRight = (activeSection: LayoutSections) => {
  return activeSection === LayoutSections.RIGHT
    ? `${CSSBlock}--active`
    : activeSection === LayoutSections.LEFT
    ? `${CSSBlock}--inactive-left`
    : `${CSSBlock}--inactive-right`;
};

const LayoutSection: React.FC<LayoutSectionProps> = ({
  data,
  alignment,
  activeSection,
  updateSectionNext,
  updateSectionPrevious,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const getContent = (index: string) => data.cards[index];
  const [selectedID, setSelectedID] = useState(data.categories[0].categoryID);
  const layoutClass = getLayoutActiveClass(activeSection, alignment);

  const onSelectorChange = (item: SectionCategory) => {
    setSelectedID(item.categoryID);
    if (contentRef.current) contentRef.current.scrollTop = 0;
  };

  return (
    <div className={`${CSSBlock} ${CSSBlock}--${alignment} ${layoutClass}`}>
      <div className={`${CSSBlock}__heading`}>
        <button
          className={`${CSSBlock}__heading-previous`}
          onClick={() => updateSectionPrevious()}>
          <i className="fa-solid fa-caret-left"></i>
        </button>
        {data.label}
        <button
          className={`${CSSBlock}__heading-next`}
          onClick={() => updateSectionNext()}>
          <i className="fa-solid fa-caret-right"></i>
        </button>
      </div>

      <div className={`${CSSBlock}__selector`}>
        {alignment === LayoutSections.MIDDLE ? (
          <VersionSelector
            items={data.categories}
            onChange={onSelectorChange}
          />
        ) : (
          <Dropdown items={data.categories} onChange={onSelectorChange} />
        )}
      </div>

      <div className={`${CSSBlock}__content-area`} ref={contentRef}>
        {getContent(selectedID).map((card, i) => (
          <Card data={card} key={i} />
        ))}
      </div>
    </div>
  );
};

export default LayoutSection;
