import React from 'react';
import {
  isContentType3D,
  isContentTypeKeyValuePair,
  isContentTypeList,
  isContentTypeText,
  SectionCard,
} from '../core/contentHelper';
import ThreeJSView from './ThreeJSView';

const CSSBlock = 'card';

const contentToNodes = (data: SectionCard) =>
  data.content?.map((content, i) => {
    if (isContentTypeText(content)) {
      return <p key={i}>{content}</p>;
    } else if (isContentTypeKeyValuePair(content)) {
      return (
        <div className={`${CSSBlock}__key-information`} key={i}>
          <span>{content.key}:</span>
          {content.value}
        </div>
      );
    } else if (isContentTypeList(content)) {
      return (
        <ul className={`${CSSBlock}__list`} key={i}>
          {content.map((listItem, i) => (
            <li key={i} className={`${CSSBlock}__list-item`}>
              {listItem}
            </li>
          ))}
        </ul>
      );
    } else if (isContentType3D(content)) {
      return <ThreeJSView key={`${content.asset}-${i}`} asset={content} />;
    }
  });

interface CardProps {
  data: SectionCard;
}

const Card: React.FC<CardProps> = ({ data }) => {
  return (
    <div className={`${CSSBlock}`}>
      {data.heading && (
        <div className={`${CSSBlock}__heading`}>{data.heading}</div>
      )}
      {data.keyInformation &&
        data.keyInformation.map(({ key, value }, i) => (
          <div className={`${CSSBlock}__key-information`} key={i}>
            <span>{key}:</span>
            {value}
          </div>
        ))}
      {contentToNodes(data)}
    </div>
  );
};

export default Card;
