import React, { useState } from 'react';
import LayoutSection from './LayoutSection';

import USBData from '../core/data/usb';
import { LayoutSections } from '../core/contentHelper';

function Content() {
  const CSSBlock = 'content';
  const [activeSection, setActiveSection] = useState<LayoutSections>(
    LayoutSections.MIDDLE
  );

  const updateSectionNext = () => {
    setActiveSection(
      activeSection === LayoutSections.LEFT
        ? LayoutSections.MIDDLE
        : activeSection === LayoutSections.MIDDLE
        ? LayoutSections.RIGHT
        : LayoutSections.LEFT
    );
  };
  const updateSectionPrevious = () => {
    setActiveSection(
      activeSection === LayoutSections.LEFT
        ? LayoutSections.RIGHT
        : activeSection === LayoutSections.MIDDLE
        ? LayoutSections.LEFT
        : LayoutSections.MIDDLE
    );
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__layout`}>
        {/* <div className={`${CSSBlock}__title`}>
          {USBData.name}
          <span className={`${CSSBlock}__long-title`}>
            [{USBData.longName}]
          </span>
        </div> */}
        {/* <div className={`${CSSBlock}__description`}>{USBData.description}</div> */}
        <LayoutSection
          activeSection={activeSection}
          data={USBData.left}
          alignment={LayoutSections.LEFT}
          updateSectionNext={updateSectionNext}
          updateSectionPrevious={updateSectionPrevious}
        />
        <LayoutSection
          activeSection={activeSection}
          data={USBData.middle}
          alignment={LayoutSections.MIDDLE}
          updateSectionNext={updateSectionNext}
          updateSectionPrevious={updateSectionPrevious}
        />
        <LayoutSection
          activeSection={activeSection}
          data={USBData.right}
          alignment={LayoutSections.RIGHT}
          updateSectionNext={updateSectionNext}
          updateSectionPrevious={updateSectionPrevious}
        />
      </div>
    </div>
  );
}

export default Content;
