export enum LSKeys {
  THEME = 'THEME',
}

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export class LocalStorage {
  static get(key: LSKeys): ThemeMode {
    const val = localStorage.getItem(key);
    switch (val) {
      case ThemeMode.LIGHT:
        return ThemeMode.LIGHT;
      case ThemeMode.DARK:
        return ThemeMode.DARK;
      default:
        return ThemeMode.SYSTEM;
    }
  }

  static set(key: LSKeys, value: ThemeMode) {
    localStorage.setItem(key, value);
  }
}
