import React, { useEffect, useRef } from 'react';
import { ContentType3D } from '../core/contentHelper';
import Simulator from '../threejs/simulator';

interface ThreeJSView {
  asset: ContentType3D;
}

const ThreeJSView: React.FC<ThreeJSView> = ({ asset }) => {
  let canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current)
      new Simulator(canvasRef.current, asset.asset, asset.scale || 1.0);
  }, [canvasRef]);

  return (
    <div className={''} style={{ textAlign: 'center' }}>
      <canvas ref={canvasRef} width={300} height={100}></canvas>
    </div>
  );
};

export default ThreeJSView;
