import React, { useEffect, useState } from 'react';
import USBData from '../core/data/usb';

const References: React.FC = () => {
  const CSSBlock = 'references';
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState('close');

  useEffect(() => {
    setIcon(open ? 'close' : 'info');
  }, [open]);

  return (
    <div className={`${CSSBlock} ${open ? `${CSSBlock}--open` : ''}`}>
      <button className={`${CSSBlock}__trigger`} onClick={() => setOpen(!open)}>
        {/* <i className={`fa-solid fa-info`}></i> */}
        {open && (
          <span>
            <i className={`fa-solid fa-close`} />
          </span>
        )}
        {!open && (
          <span>
            <i className={`fa-solid fa-info`} />
          </span>
        )}
      </button>
      <div
        className={`${CSSBlock}__overlay ${
          open ? `${CSSBlock}__overlay--open` : ''
        }`}>
        <div className={`${CSSBlock}__title`}>References</div>
        {USBData.references.map((ref, i) => (
          <div className={`${CSSBlock}__item`}>
            <a href={ref} key={i} target="_blank">
              {ref}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default References;
